import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }, {
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }, {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  }, {
    path: '/myAssessment',
    name: 'MyAssessment',
    component: () => import('../views/MyAssessment.vue')
  }, {
    path: '/personalityAssessment',
    name: 'PersonalityAssessment',
    component: () => import('../views/PersonalityAssessment.vue')
  }, {
    path: '/mbti',
    name: 'MBTI',
    component: () => import('../views/MBTI.vue')
  }, {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import('../views/UserInfo.vue')
  }, {
    path: '/answer',
    name: 'Answer',
    component: () => import('../views/Answer.vue')
  }, {
    path: '/perfectInfo',
    name: 'PerfectInfo',
    component: () => import('../views/PerfectInfo.vue')
  }, {
    path: '/evaluationReport',
    name: 'EvaluationReport',
    component: () => import('../views/EvaluationReport.vue')
  }, {
    path: '/evaluationReportToken',
    name: 'EvaluationReportToken',
    component: () => import('../views/EvaluationReportToken.vue')
  }, {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/Notice.vue')
  }, {
    path: '/status/:token',
    name: 'Status',
    component: () => import('../views/Status.vue')
  },{
    path: '/evaluationReportTokenInfo',
    name: 'EvaluationReportTokenInfo',
    component: () => import('../views/EvaluationReportTokenInfo.vue')
  }, {
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue')
  }, {
    path: '/reportToken',
    name: 'ReportToken',
    component: () => import('../views/ReportToken.vue')
  }, {
    path: '/comprehensiveReport',
    name: 'ComprehensiveReport',
    component: () => import('../views/comprehensiveReport.vue')
  }, 
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
