<template>
  <div id="app">
    <router-view />
  </div>
</template>



<script type="text/ecmascript-6">
  export default {
    data() {
      return {};
    },
  };
</script>

<style>
@import "./assets/css/reset.css";
</style>
