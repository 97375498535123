import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import font from './utils/font'
import Vant from 'vant'
import 'vant/lib/index.css'
import less from 'less'
import Router from 'vue-router'
// import Http from '@/api/item/index' 

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

router.afterEach(() => {
  window.scrollTo(20, 0);
})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') {
//     next();
//   } else {
//     let token = localStorage.getItem('authorization');

//     if (token === null || token === '') {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// });


Vue.config.productionTip = false
// Vue.prototype.Http = Http
Vue.use(font)
Vue.use(Vant)
Vue.use(less)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
