import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authorization: localStorage.getItem('authorization') ? localStorage.getItem('authorization') : '',
    loginUser: localStorage.getItem('loginUser') ? localStorage.getItem('loginUser') : ''
  },
  mutations: {
    changeLogin(state, res) {
      state.authorization = res;
      localStorage.setItem('authorization', res);
    },
    loginUser(state, res) {
      state.authorization = res;
      localStorage.setItem('loginUser', res);
    },
  },
  actions: {
  },
  modules: {
  }
})
